import React, { useEffect } from "react";
import { Grid, TextField, Card, Typography, Box, Button, FormControl, Stack, MenuItem, InputLabel, Select } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";
import { dateValueToYYYYMMDD } from "../../helper/TimeHelper";
import CommonDataSelection from "../common/CommonDataSelection/CommonDataSelection";
import CommonDateRangePicker from "../common/CommonDateRangePicker";
import { generateReport } from "../../api/StatApi";
import { set } from "date-fns";
// import { downloadWithURL } from "../../helper/ExportHelper";
import { downloadWithURL } from "../../helper/ExportHelper";


import { toast } from "react-toastify";

const getReportList = () => {
    return [
        { 'id': 'member_play_skill_data', 'caption': '學生技能資料' },
        { 'id': 'member_play_daily_skill_stat', 'caption': '學生每日技能統計' },
        { 'id': 'member_redemption_data', 'caption': '學生兌換資料' },
        { 'id': 'member_redemption_earn_data', 'caption': '學生獲取兌換點數資料' },
    ]
}





export default function ReportGenerationForm({ reportList, onQuery, onExport }) {
    // const [eventID, setEventID] = React.useState(-1); // getDefaultEventID(eventList));
    // const [event, setEvent] = React.useState(null);
    const [reportType, setReportType] = React.useState("");

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);


    useEffect(() => {
        // const initialEventID = getDefaultEventID(eventList);
        // setEventID(initialEventID);
        // var event = getEventFromList(eventList, initialEventID);
        // setEvent(event);

    }, []);

    const onReportChanged = (evt) => {
        setReportType(evt.target.value);

        // setEventID(evt.target.value);

        // var event = getEventFromList(eventList, evt.target.value);
        // setEvent(event);

    };

    const createQueryFilter = () => {
        return {
            reportType,
            startDate: dateValueToYYYYMMDD(startDate),
            endDate: dateValueToYYYYMMDD(endDate),
        }
    };

    const onExportClicked = () => {
        const query = createQueryFilter();

        if (onExport != null && onExport === undefined) {
            onExport(query);

        }

        generateReportFromServer(query);
    }

    const generateReportFromServer = async (queryFilter) => {
        // const result = await StatApi.generateReport(reportType, startDate, endDate);
        const reportType = queryFilter.reportType;
        const startDate = queryFilter.startDate;
        const endDate = queryFilter.endDate;

        if (reportType === undefined || reportType === "") {
            toast.error("請選擇報告類型");
            return;
        }

        if (startDate === undefined || startDate === null) {
            toast.error("請選擇開始日期");
            return;
        }

        if (endDate === undefined || endDate === null) {
            toast.error("請選擇結束日期");
            return;
        }

        const result = await generateReport(reportType, startDate, endDate);

        if (!result) {
            return;
        }
        console.debug("result: ", result);


        downloadWithURL(result.export_url, result.export_filename);

        // if (result.success) {
        toast.success("報告已產生");

    }


    const onQueryClicked = () => {
        if (onQuery != null) {
            onQuery(createQueryFilter());
        }
    }

    // skill control




    return (<Card>
        <Box sx={{ padding: 2 }}>
            {/* <Typography variant="h5">Event Leaderboard</Typography> */}
            {/* {eventSelection} */}
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <CommonDataSelection
                        key="reportType"
                        label="報告類型"
                        value={reportType}
                        dataList={getReportList()}
                        captionFn={(item) => item.caption}
                        onChange={onReportChanged}
                    />
                </Grid>

                <Grid item xs={8}>
                    <CommonDateRangePicker
                        defaultRange="30d"

                        onChange={([startDate, endDate]) => {
                            console.debug("startDate: ", startDate);
                            console.debug("endDate: ", endDate);
                            setStartDate(startDate);
                            setEndDate(endDate);
                            // var dateStr = `${dateValueToYYYYMMDD(startDate)} ~ ${dateValueToYYYYMMDD(endDate)}`;
                            // setDateRange(dateStr);
                        }}
                    ></CommonDateRangePicker>
                </Grid>



            </Grid>
            <Grid container spacing={2} pt={1}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" onClick={onExportClicked}>匯出</Button>
                </Grid>
            </Grid>




        </Box>
        <pre>
            {/* DEBUG: eventID={eventID} timeslot: {timeslotID} skill: {skillID} selectedDate: {dateValueToTimeStr(selectedDate)} 
            <br/>
            {JSON.stringify(eventList, null, 2)} */}
        </pre>
    </Card>);
}