import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Container, Typography } from '@mui/material';
import ReportGenerationForm from "../components/report/ReportGenerationForm";

// ----------------------------------------------------------------------

export default function ReportGenerationPage() {
  const theme = useTheme();

  return (
    <>
      <title> Event Statistics </title>


      <Container maxWidth="xl">
        <Stack direction="column">
          <Typography variant="h4" sx={{ mb: 5 }}>
            報表系統
          </Typography>
          <ReportGenerationForm />
        </Stack>

      </Container>
    </>
  );
}
