import axios from "../helper/AxiosHelper";
import ApiHelper from "./ApiHelper";


export const generateReport = async (reportType, startDate, endDate) => {
  // report/generateDailyReport?date=2021-10-01

  // reporting/generateReport
  const url = `/admin/reporting/generateReport?report_type=${reportType}&start_date=${startDate}&end_date=${endDate}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
}

export const getEventStat = async (eventID, date) => {
  if (date === undefined || date == null) {
    date = "";
  }

  // eventStat/getStat?event_id=14&date
  const url = `/eventStat/getStat?event_id=${eventID}&&date=${date}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
}



export const StatApi = {
  generateReport,
  getEventStat,
};
